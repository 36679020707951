<template>
  <div class="diadin">
    <!-- 头部标题 -->
    <!-- <tendency :list="scot" :id="1"></tendency> -->

    <!-- 表格 -->
    <el-table :data="tableData" style="width: 100%" @sort-change="sortByDate" :default-sort="{ prop: prop, order: type }">
      <el-table-column prop="title" label="商品" width="500">
        <template slot-scope="scope">
          <div style="display: flex">
            <div style="width: 80px; height: 80px; margin-right: 10px">
              <img style="width: 80px; height: 80px" :src="(scope.row.imageUrl ? scope.row.imageUrl : require('../../assets/imgs/empty.png')) | image(80,80)" alt="" />
            </div>
            <div style="width: 420px">
              <div style="color: #013bfe">
                <span style="
                    background-color: #e5f2fd;
                    padding: 2px 5px;
                    margin-right: 5px;
                  ">{{ scope.row.category }}</span>
                <span @click="details()" style="cursor: pointer;">{{ scope.row.name }}</span>
              </div>
              <div>
                <span style="
                  cursor: pointer;
                    background-color: #f2f3f5;
                    padding: 2px 5px;
                    margin-right: 5px;
                  " @click="details1">数据详情</span>
                <span style="
                  cursor: pointer;
                    background-color: #f2f3f5;
                    padding: 2px 5px;
                    margin-right: 5px;
                  "><a :href="scope.row.detailLink" target="_blank">商品链接</a></span>
                <span style="
                  cursor: pointer;
                    background-color: #f2f3f5;
                    padding: 2px 5px;
                    margin-right: 5px;
                  "><a :href="scope.row.shopLink" target="_blank">店铺链接</a></span>

              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="销量趋势" width="130">
        <template slot-scope="scope">
          <div>
            <tendency :list="scope.row.goodsTrendList" :id="scope.row.id"></tendency>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="complete" sortable="custom" label="价格" width="130">
        <template slot-scope="scope">
          <div style="width: 100%; text-align: center">
            <div style="color: #f5762c">
              {{ scope.row.minPrice }} - {{ scope.row.maxPrice }}
            </div>
            <div style="font-size: 12px">
              {{ scope.row.minChinaPrice }} - {{ scope.row.maxChinaPrice }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="销量">
        <el-table-column prop="goodsSumSold" sortable label="总">
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <div>{{ scope.row.goodsSumSold }}</div>
              <div style="font-size: 12px; color: #50b52c" v-if="
                  scope.row.goodsSumSoldIncrease !== null &&
                  Number(scope.row.goodsSumSoldIncrease) < 0
                ">
                <i class="el-icon-caret-bottom"></i>{{ scope.row.goodsSumSoldIncrease }}%
              </div>
              <div style="font-size: 12px; color: red" v-if="
                  scope.row.goodsSumSoldIncrease !== null &&
                  Number(scope.row.goodsSumSoldIncrease) > 0
                ">
                <i class="el-icon-caret-top"></i>{{ scope.row.goodsSumSoldIncrease }}%
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsDailySold" sortable label="日">
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <div>{{ scope.row.goodsDailySold }}</div>
              <div style="font-size: 12px; color: #50b52c" v-if="
                  scope.row.goodsDailySoldIncrease !== null &&
                  Number(scope.row.goodsDailySoldIncrease) < 0
                ">
                <i class="el-icon-caret-bottom"></i>{{ scope.row.goodsDailySoldIncrease }}%
              </div>
              <div style="font-size: 12px; color: red" v-if="
                  scope.row.goodsDailySoldIncrease !== null &&
                  Number(scope.row.goodsDailySoldIncrease) > 0
                ">
                <i class="el-icon-caret-top"></i>{{ scope.row.goodsDailySoldIncrease }}%
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsWeekSold" sortable label="周">
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <div>{{ scope.row.goodsWeekSold }}</div>
              <div style="font-size: 12px; color: #50b52c" v-if="
                  scope.row.goodsWeekSoldIncrease !== null &&
                  Number(scope.row.goodsWeekSoldIncrease) < 0
                ">
                <i class="el-icon-caret-bottom"></i>{{ scope.row.goodsWeekSoldIncrease }}%
              </div>
              <div style="font-size: 12px; color: red" v-if="
                  scope.row.goodsWeekSoldIncrease !== null &&
                  Number(scope.row.goodsWeekSoldIncrease) > 0
                ">
                <i class="el-icon-caret-top"></i>{{ scope.row.goodsWeekSoldIncrease }}%
              </div>
            </div>
          </template></el-table-column>
        <el-table-column prop="goodsMonthSold" sortable label="月">
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <div>{{ scope.row.goodsMonthSold }}</div>
              <div style="font-size: 12px; color: #50b52c" v-if="
                  scope.row.goodsMonthSoldIncrease !== null &&
                  Number(scope.row.goodsMonthSoldIncrease) < 0
                ">
                <i class="el-icon-caret-bottom"></i>{{ scope.row.goodsMonthSoldIncrease }}%
              </div>
              <div style="font-size: 12px; color: red" v-if="
                  scope.row.goodsMonthSoldIncrease !== null &&
                  Number(scope.row.goodsMonthSoldIncrease) > 0
                ">
                <i class="el-icon-caret-top"></i>{{ scope.row.goodsMonthSoldIncrease }}%
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="关联店铺">
        <el-table-column prop="shopSumSold" sortable label="总">
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <div>{{ scope.row.shopSumSold }}</div>
              <div style="font-size: 12px; color: #50b52c" v-if="
                  scope.row.shopSumSoldIncrease !== null &&
                  Number(scope.row.shopSumSoldIncrease) < 0
                ">
                <i class="el-icon-caret-bottom"></i>{{ scope.row.shopSumSoldIncrease }}%
              </div>
              <div style="font-size: 12px; color: red" v-if="
                  scope.row.shopSumSoldIncrease !== null &&
                  Number(scope.row.shopSumSoldIncrease) > 0
                ">
                <i class="el-icon-caret-top"></i>{{ scope.row.shopSumSoldIncrease }}%
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shopDailySold" sortable label="日">
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <div>{{ scope.row.shopDailySold }}</div>
              <div style="font-size: 12px; color: #50b52c" v-if="
                  scope.row.shopDailySoldIncrease !== null &&
                  Number(scope.row.shopDailySoldIncrease) < 0
                ">
                <i class="el-icon-caret-bottom"></i>{{ scope.row.shopDailySoldIncrease }}%
              </div>
              <div style="font-size: 12px; color: red" v-if="
                  scope.row.shopDailySoldIncrease !== null &&
                  Number(scope.row.shopDailySoldIncrease) > 0
                ">
                <i class="el-icon-caret-top"></i>{{ scope.row.shopDailySoldIncrease }}%
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shopWeekSold" sortable label="周">
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <div>{{ scope.row.shopWeekSold }}</div>
              <div style="font-size: 12px; color: #50b52c" v-if="
                  scope.row.shopWeekSoldIncrease !== null &&
                  Number(scope.row.shopWeekSoldIncrease) < 0
                ">
                <i class="el-icon-caret-bottom"></i>{{ scope.row.shopWeekSoldIncrease }}%
              </div>
              <div style="font-size: 12px; color: red" v-if="
                  scope.row.shopWeekSoldIncrease !== null &&
                  Number(scope.row.shopWeekSoldIncrease) > 0
                ">
                <i class="el-icon-caret-top"></i>{{ scope.row.shopWeekSoldIncrease }}%
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shopMonthSold" sortable label="月">
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <div>{{ scope.row.shopMonthSold }}</div>
              <div style="font-size: 12px; color: #50b52c" v-if="
                  scope.row.shopMonthSoldIncrease !== null &&
                  Number(scope.row.shopMonthSoldIncrease) < 0
                ">
                <i class="el-icon-caret-bottom"></i>{{ scope.row.shopMonthSoldIncrease }}%
              </div>
              <div style="font-size: 12px; color: red" v-if="
                  scope.row.shopMonthSoldIncrease !== null &&
                  Number(scope.row.shopMonthSoldIncrease) > 0
                ">
                <i class="el-icon-caret-top"></i>{{ scope.row.shopMonthSoldIncrease }}%
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="onlineTime" sortable label="上架时间">
      </el-table-column>
      <el-table-column prop="shopTime" sortable label="开店时间">
      </el-table-column>
    </el-table>
    <div style="margin: 10px auto; text-align: center">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="curPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totale">
      </el-pagination>
    </div>
  </div>
</template>
    
    <script>
import { } from "../../api/api";
import tendency from "../../components/tendency";
export default {
  components: {
    tendency,
  },
  data() {
    return {
      formInline: {
        name: "",
        status: "0",
      },
      scot: [
        {
          date: 1,
          value: 0

        },
        {
          date: 1,
          value: 0

        },
        {
          date: 1,
          value: 0

        },
        {
          date: 1,
          value: 0

        },
        {
          date: 1,
          value: 500

        },
        {
          date: 1,
          value: 0

        },
        {
          date: 1,
          value: 500

        },
      ],
      ullist: [
        {
          img: require("../../assets/images/doc.png"),
          titile: "查商品",
          introduce: "在已经上架的商品中洞悉市场行情找到潜在的机会",
          list: [
            {
              title: "跨境热销榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "持续热销榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "Temu热销榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "SHein热销榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "热销新品榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "潜力爆品榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "活动热销榜",
              url: "/buffer?type=1&status=1",
            },
          ],
        },
        {
          img: require("../../assets/images/doc.png"),
          titile: "查店铺",
          introduce: "从海量店铺中找到潜力店铺分析竞争对手",
          list: [
            {
              title: "跨境热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "持续热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "Temu热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "SHein热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "新店热度榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "热搜热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "活动热店榜",
              url: "/buffer?type=2&status=1",
            },
          ],
        },
      ],
      upproject: false,
      dialogVisible: false,
      finishId: "",
      field: "",
      sort: "",
      curPage: 1,
      pageSize: 10,
      totale: 0,
      tableData: [],
    };
  },
  created() {
    // this.biao();
  },
  methods: {
    tzly(e) {
      this.$router.push(e)
      sessionStorage.setItem("activeMenu", e)
    },
    sortByDate(e) {
      console.log(e);
      if(e.order == "ascending") {
        this.sort = "asc";
      } else if(e.order == "descending") {
        this.sort = "desc";
      } else {
        this.sort = "";
      }
      if(e.prop == "goodsSumSold") {
        this.field = "goods_sum_sold";
      }
      if(e.prop == "goodsDailySold") {
        this.field = "goods_daily_sold";
      }
      if(e.prop == "goodsWeekSold") {
        this.field = "goods_week_sold";
      }
      if(e.prop == "goodsMonthSold") {
        this.field = "goods_month_sold";
      }
      if(e.prop == "shopSumSold") {
        this.field = "shop_sum_sold";
      }
      if(e.prop == "shopDailySold") {
        this.field = "shop_daily_sold";
      }
      if(e.prop == "shopWeekSold") {
        this.field = "shop_week_sold";
      }
      if(e.prop == "shopWeekSoldIncreas") {
        this.field = "shop_month_sold";
      }
      if(e.prop == "onlineTime") {
        this.field = "online_time";
      }
      if(e.prop == "shopTime") {
        this.field = "shop_online_time";
      }
      this.biao();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.biao();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val;
      this.biao();
    },
    async biao() {
      let param = {};
      // param["page"] = this.curPage;
      param["page"] = this.curPage;
      param["size"] = this.pageSize;
      param["field"] = this.field;
      param["sort"] = this.sort;

      const { data: res } = await producthome(param);

      this.tableData = res.data.records;

      this.totale = res.data.total;
      this.curPage = res.data.current;
      this.pagesize = res.data.size;
    },
    // 新建计划弹框
    project() {
      this.upproject = true;
      this.$refs[formName].resetFields();
    },
    //选择状态
    statusBoutton(e) {
      this.formInline.status = e.id;
    },
    // 结束按钮
    finish(e) {
      this.dialogVisible = true;
      this.finishId = e.id;
    },
    // 确认结束
    finishBont() {
      this.dialogVisible = false;
      // this.finishId 当前选择结束的ID
    },
    // 新增计划
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if(valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    details() {
      // this.$router.push({
      //   path: "/workbench/details",
      //   query: {},
      // });
    },
    details1() {
      this.$router.push({
        path: "/shopdeild",
        query: {},
      });
    },
  },
};
</script>
    
    <style lang="less" scoped>
.diadin {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 12px;
}
.display {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  font-size: 18px;
}
.border {
  border-bottom: 1px solid #eeeeee;
}
.minisize {
  font-style: 14px;
  span {
    margin: 0 8px;
    cursor: pointer;
  }
  .stotusbox {
    color: #d4d4d4;
    font-size: 15px;
  }
}

/*包含以下五种的链接*/
a {
  text-decoration: none !important;
  color: #606266 !important;
}
.actoin {
  color: #409eff !important;
}
.ulbox {
  background-image: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgb(255, 255, 255),
    rgb(255, 255, 255),
    rgba(156, 206, 238, 0.16),
    rgba(156, 206, 238, 0.263)
  );
  width: 50%;
  height: 280px;
  border-radius: 12px;
  padding: 20px;
  min-width: 350px;
  box-sizing: border-box;
  color: #4e7ce0;
  margin-right: 15px;
}
.flexa {
  display: flex;
  align-items: center;
}
.ulbox li {
  position: relative;
  padding-left: 25px;

  margin-bottom: 10px;
}

.ulbox li::before {
  position: absolute;

  content: "";

  left: 0;

  top: 4px;

  width: 6px;

  height: 6px;

  background-color: #4e7ce0;

  border-radius: 50%;
}
</style>