<template>
  <div :id="id" class="chart" :style="{width: '100%', height: '100%'}"></div>
</template>
  
  <script>
import * as echarts from 'echarts';
export default {
  name: 'xwPassengerFlow',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    id: {
      type: [String, Number],
      default() {
        return 0;
      }
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    this.loadLine()
  },
  watch: {
    id(val) {
      this.$nextTick(() => {
        this.loadLine();
      })
    },
    list(val) {
      this.$nextTick(() => {
        this.loadLine();
      })
    }
  },
  methods: {
    loadLine() {
      let a = this.list
      let b = []
      let c = []
      if(a.length > 0) {
        a.forEach((e, i) => {
          b.push(e.date)
          c.push(e.value || e.saleSum || 0)
        });
      }
      const option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          splitLine: {//坐标轴在grid区域中的分割线
            show: false
          },
          axisLabel: {//坐标轴刻度标签
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: b
        },
        yAxis: {
          type: 'value',
          splitLine: {//坐标轴在grid区域中的分割线
            show: false
          },
          axisLabel: {//坐标轴刻度标签
            show: false
          },
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          confine: true,
          backgroundColor: 'rgba(255,255,255,0.2)',//通过设置rgba调节背景颜色与透明度
        },
        series: [
          {
            data: c,
            type: 'line',
            // smooth: 0.6,
            symbol: 'none',
            lineStyle: {
              color: '#5470C6',
              width: 1
            },
            // markLine: {
            //   symbol: ['none', 'none'],
            //   label: { show: false },
            //   data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }]
            // },
            areaStyle: {

              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#e7effb", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#c3d6f6", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              origin: "start",
            },
            smooth: true,

            itemStyle: {
              opacity: 0,
            }
          }
        ]
      }
      this.myChartOne = echarts.init(document.getElementById(this.id))
      this.myChartOne.setOption(option)
    }
  }
}
  </script>